<template>
  <div>
    <v-row v-if="!loading" justify="center" >

      <v-col cols="11" sm="6" md="3" lg="3">
        <router-link :to="{name: 'admin-manage-market-request'}">
          <v-card style="border-radius: 32px; min-height: 200px">
            <v-card-title class=" headline">
              <v-icon left>mdi-cart</v-icon>Market Requests
            </v-card-title>
            <v-card-text>
              <line-chart :chart-data="marketRequests"></line-chart>
            </v-card-text>
          </v-card>
        </router-link>
      </v-col>


      <v-col cols="11" sm="6" md="3" lg="3">
        <router-link :to="{name: 'admin-manage-company'}">
          <v-card style="border-radius: 32px; min-height: 200px">
            <v-card-title class=" headline">
              <v-icon left>mdi-domain</v-icon>Companies
            </v-card-title>
            <v-card-text class=" text-center">
              <v-row>
                <v-col cols="12" sm="6">
                  <h2 class="primary--text">Active</h2>
                  <p class="display-1">{{companies.active}}</p>
                </v-col>
                <v-col cols="12" sm="6" >
                  <h2 class="error--text">Pending</h2>
                  <p class="display-1">{{companies.pending}}</p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </router-link>
      </v-col>

      <v-col cols="11" sm="6" md="3" lg="3">
        <router-link :to="{name: 'admin-manage-depot'}">
          <v-card style="border-radius: 32px; min-height: 200px">
            <v-card-title class=" headline">
              <v-icon left>mdi-home</v-icon>Depots
            </v-card-title>
            <v-card-text class=" text-center">
              <p class="display-3">{{depots}}</p>
            </v-card-text>
          </v-card>
        </router-link>
      </v-col>

      <v-col cols="11" sm="6" md="3" lg="3">
        <router-link :to="{name: 'admin-manage-users'}">
          <v-card style="border-radius: 32px; min-height: 200px">
            <v-card-title class=" headline">
              <v-icon left>mdi-account-group</v-icon>Users
            </v-card-title>
            <v-card-text class=" text-center">
              <p class="display-3">{{users}}</p>
            </v-card-text>
          </v-card>
        </router-link>
      </v-col>
    </v-row>

    <v-row v-else justify="center" class="mt-3">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-row>
  </div>
</template>

<script>
  import {gql} from 'apollo-boost'
  import LineChart from '@/components/Charts/Pie'
  export default{

    components: {LineChart},
    data() {
      return {
        collection: null,
        marketRequests: {},
        depots: 0,
        users: 0,
        companies: {
          active: 0,
          pending: 0
        },
        loading: true,
      }
    },
    created(){
      this.$apollo.query({
        query: gql`{
          marketRequests{id status}
          companies{id status}
          depots{id}
          users{id}
          }
        `,
      }).then(res=>{
        this.setMarketRequestSummary(res.data.marketRequests)
        this.setCompaniesSummary(res.data.companies)
        this.depots = res.data.depots.length
        this.users = res.data.users.length
        this.loading = false
      }).catch(err=>{
        console.error(err)
      })
    },
    methods: {
      setMarketRequestSummary(marketRequests){
        let summary = {
          pending: 0,
          success: 0,
          failed: 0,
          awaiting_pickup: 0,
          on_route: 0
        }
        marketRequests.forEach(request=>{
          switch(request.status){
            case 'pending' :
              summary.pending += 1
              break
            case 'success' :
              summary.success += 1
              break
            case 'awaiting pickup' :
              summary.awaiting_pickup += 1
              break
            case 'on route' :
              summary.on_route += 1
              break
            case 'failed' :
              summary.failed += 1
              break
          }
        })
        this.marketRequests.datasets = [
          {
              label: 'Pending',
              data: [summary.pending, 0],
              backgroundColor: [
                  '#29292933',
              ],
              borderColor: [
                  '#292929',
              ],
              borderWidth: 1
          },
          {
              label: 'Awaiting pickup',
              data: [summary.awaiting_pickup, 0],
              backgroundColor: [
                  '#ffa21a33',
              ],
              borderColor: [
                  '#ffa21a',
              ],
              borderWidth: 1
          },
          {
              label: 'On route',
              data: [summary.on_route, 0],
              backgroundColor: [
                  '#00d3ee33',
              ],
              borderColor: [
                  '#00d3ee',
              ],
              borderWidth: 1
          },
          {
              label: 'Successful',
              data: [summary.success, 0],
              backgroundColor: [
                  '#5cb86033',
              ],
              borderColor: [
                  '#5cb860',
              ],
              borderWidth: 1
          },
          {
              label: 'Failed',
              data: [summary.failed, 0],
              backgroundColor: [
                  '#f55a4e33',
              ],
              borderColor: [
                  '#f55a4e',
              ],
              borderWidth: 1
          }
        ]
        console.log('marketRequests', summary)
      },

      setCompaniesSummary(companies){
        let summary =  {
          active: 0,
          pending: 0
        }
        companies.forEach(company=>{
          company.status == 'pending' ? summary.pending += 1 : summary.active += 1
        })
        this.companies = summary
        console.log('companies', summary)

      }
    }
  }
</script>
